import { Box, Flex, Stack, createStyles } from '@mantine/core'
import { CSSProperties } from 'react'

import { ThatchAvatar } from '~/components/shared/ThatchAvatarDynamic'
import { ThatchLink } from '~/components/shared/ThatchLink'
import { getImageUrl } from '~/components/shared/image/helpers'
import { Typography, Variant } from '~/components/shared/text/Typography'
import { ProfileBasicSummaryType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'

// eslint-disable-next-line import/no-cycle
import { AvailableForHireTag } from '../seller/AvailableForHireTag'

interface BoardAuthorWithoutContextProps {
  author: ProfileBasicSummaryType
  desktopPhotoSize?: number
  mobilePhotoSize?: number
  photoGap?: number
  titleStyle?: CSSProperties
  containerStyle?: CSSProperties
  titleVariant?: Variant
  tagVariant?: Variant
  showAvailableForHireTag?: boolean
  hideLabelPhoto?: boolean
  handleGuideClick?: any
}

const useStyle = createStyles(_theme => ({
  container: {
    margin: '20px 0 24px 0',
  },
}))

export const BoardAuthorWithoutContext: React.FC<BoardAuthorWithoutContextProps> = ({
  author,
  desktopPhotoSize = 40,
  mobilePhotoSize = 40,
  photoGap = 12,
  titleStyle = {},
  containerStyle = {},
  titleVariant = 'body2SerifItalic',
  tagVariant,
  showAvailableForHireTag = false,
  hideLabelPhoto,
  handleGuideClick,
}) => {
  const { classes } = useStyle()
  const { isMobileScreen } = useScreenSize()

  const { hireable, name, photo, username, uid } = author

  return (
    <Box
      className={classes.container}
      style={containerStyle}
    >
      <Box
        tabIndex={-1}
        onClick={handleGuideClick}
      >
        <ThatchLink
          to={`/@${username}`}
          isOpenInNewWindow
          underline={false}
        >
          <Flex
            gap={photoGap}
            align="center"
          >
            {!hideLabelPhoto && photo && (
              <ThatchAvatar
                url={getImageUrl({
                  src: photo,
                  width: isMobileScreen ? mobilePhotoSize : desktopPhotoSize,
                })}
                desktopSize={desktopPhotoSize}
                mobileSize={mobilePhotoSize}
              />
            )}
            <Stack
              sx={{ flexDirection: 'column' }}
              spacing={3}
            >
              <Typography
                span
                variant={titleVariant}
                style={titleStyle}
                lineClamp={1}
                sx={{ fontSize: '16px ' }}
                className='pr-1 pb-0.5'
              >
                {name}
              </Typography>

              {(showAvailableForHireTag || hireable) && (
                <AvailableForHireTag variant={tagVariant} />
              )}
            </Stack>
          </Flex>
        </ThatchLink>
      </Box>
    </Box>
  )
}
