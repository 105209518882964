import clsx from 'clsx'

import { typography } from '~/theme'

import { appColors } from '../../../tailwind.config'
import { SvgIconNative, SvgIconType } from '../shared/image/SvgIconNative'
import { Variant } from '../shared/text/Typography'

export const AvailableForHireTag = ({
  variant = 'tag',
  textClassName = '',
}: {
  variant?: Variant
  textClassName?: string
}) => {
  return (
    <div className="flex items-center gap-1">
      <SvgIconNative
        type={SvgIconType.CHECK_CIRCLE}
        fill={appColors['app-fuscia']}
        width={12}
        height={12}
      />
      <span
        className={clsx(textClassName, 'text-app-fuscia')}
        style={typography[variant]}
      >
        Available for hire
      </span>
    </div>
  )
}
