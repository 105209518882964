import { Flex, Modal, createStyles } from '@mantine/core'
import { useEffect, useState } from 'react'

import { AuthEventsEnum, LaunchSource } from '~/analytics/AuthEvent'
import { LoginHome } from '~/components/auth/LoginHome'
import { RegisterHome } from '~/components/auth/RegisterHome'
import { newThatchDialogStyle } from '~/components/shared/dialog/ThatchDialogStyle'
import { useAnalytics } from '~/context/SegmentProvider'
import { useScreenSize } from '~/hooks'

interface InlineAuthViewProps {
  initLandingView: 'login' | 'signup'
  onClose: () => void
  isOpened: boolean
  onEmailAuthSuccess: (uid: string, source: 'login' | 'signup') => Promise<void>
  isInlinePurchase?: boolean
  launchSource: LaunchSource
  boardToken?: string
  profileName?: string
}

const useStyle = createStyles((theme, { height }: { height: number }) => ({
  modalHeader: {
    position: 'unset',
    padding: 0,
    marginBottom: 0,
    justifyContent: 'flex-end',
    paddingTop: 12,
    paddingRight: 12,
  },
  modalContent: {
    height,
    padding: 0,
    overflowX: 'hidden',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: '100%',
      height: '100%',
    },
    [`@media (max-width: ${theme.breakpoints.xs})`]: {
      maxHeight: '100vh',
    },
  },
  modalBody: {
    padding: 0,
  },
}))

export const InlineAuthView: React.FC<InlineAuthViewProps> = ({
  initLandingView,
  onClose,
  isOpened,
  onEmailAuthSuccess: onAuthSuccess,
  isInlinePurchase = false,
  launchSource,
  boardToken,
  profileName,
}) => {
  const { classes: dialogClasses } = newThatchDialogStyle({ height: 700 })
  const { isMobileScreen } = useScreenSize()
  const { classes } = useStyle({ height: 700 })
  const [landingView, setLandingView] = useState<'login' | 'signup'>(initLandingView)
  const thatchSegment = useAnalytics()

  useEffect(() => {
    if (isOpened && typeof window !== 'undefined') {
      thatchSegment.trackAuthEvent(AuthEventsEnum.enum.SignUpStarted, {
        button_clicked: launchSource,
        source_url: window.location.href,
        board_token: boardToken,
      })
    }
  }, [boardToken, isOpened, launchSource, thatchSegment])

  const handleAuthCompletion = async (uid: string) => {
    thatchSegment.trackAuthEvent(AuthEventsEnum.enum.SignUpCompleted, {
      button_clicked: launchSource,
      source_url: window.location.href,
      board_token: boardToken,
      user_uid: uid,
    })
    await onAuthSuccess(uid, landingView)
  }

  return (
    <Modal
      opened={isOpened}
      onClose={onClose}
      fullScreen={isMobileScreen}
      scrollAreaComponent={Modal.NativeScrollArea}
      centered
      size={680}
      classNames={{
        content: classes.modalContent,
        root: dialogClasses.modalRoot,
        close: dialogClasses.modalCloseButton,
        header: classes.modalHeader,
        body: dialogClasses.modalBody,
      }}
    >
      <Flex
        direction="column"
        justify="center"
        align="center"
      >
        {landingView == 'login' ? (
          <LoginHome
            isInlined
            isInlinePurchase={isInlinePurchase}
            onSwitchView={val => setLandingView(val)}
            onEmailAuthSuccess={handleAuthCompletion}
            authRedirectEventPayload={{
              button_clicked: launchSource,
              source_url: window.location.href,
              board_token: boardToken,
            }}
          />
        ) : (
          <RegisterHome
            isInlined
            launchSource={launchSource}
            isInlinePurchase={isInlinePurchase}
            onSwitchView={val => setLandingView(val)}
            onEmailAuthSuccess={handleAuthCompletion}
            profileName={profileName}
            authRedirectEventPayload={{
              button_clicked: launchSource,
              source_url: window.location.href,
              board_token: boardToken,
            }}
          />
        )}
      </Flex>
    </Modal>
  )
}
