import { PasswordInput, Space, TextInput, createStyles } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import React from 'react'

import { ThatchButton } from '~/components/shared/ThatchButton'

const useStyle = createStyles(theme => ({
  form: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    [`@media (min-width: ${theme.breakpoints.sm})`]: {
      maxWidth: 343,
    },
    [`@media (max-width: ${theme.breakpoints.xs})`]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  input: {
    fontSize: 22,
    height: 48,
  },
}))

export interface LoginFormValues {
  email: string
  password: string
}

interface LoginFormProps {
  form: UseFormReturnType<LoginFormValues>
  handleSubmit: ({ email, password }: LoginFormValues) => void
  isBusy?: boolean
}

export const LoginForm: React.FC<LoginFormProps> = ({ form, handleSubmit, isBusy = false }) => {
  const { classes } = useStyle()

  return (
    <form
      onSubmit={form.onSubmit(handleSubmit)}
      className={classes.form}
    >
      <TextInput
        placeholder="Email"
        h={48}
        w="100%"
        classNames={{
          input: classes.input,
        }}
        readOnly={isBusy}
        {...form.getInputProps('email')}
      />
      <PasswordInput
        mt={28}
        h={48}
        w="100%"
        placeholder="Password"
        classNames={{
          input: classes.input,
          innerInput: classes.input,
        }}
        readOnly={isBusy}
        {...form.getInputProps('password')}
      />
      <Space h={56} />
      <ThatchButton
        w={256}
        size="mobileLarge"
        type="submit"
        label="Sign in"
        loading={isBusy}
      />
    </form>
  )
}
