export enum CreatorEvent {
  HireMeClicked = 'hire_me_modal_viewed',
}

export type TrackingPageSource = 'serp' | 'guide card' | 'guide details' | 'profile'

export type CreatorEventPayload = {
  source: TrackingPageSource
  creatorToken: string
  creatorUsername: string
}
