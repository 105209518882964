import type { Config } from 'tailwindcss'
import defaultTheme from 'tailwindcss/defaultTheme'

export const appColors = {
  'app-yellow': '#FBE69B',
  'app-pink': '#F7CFEE',
  'app-placeholder': '#595959',
  'app-paper': '#F3F2EF',
  'app-orange': '#D66F3D',
  'app-puddy': '#EACEBF',
  'app-fuscia': '#D05BB9',
  'app-mint': '#D7E5CB',
  'app-lavender': '#CEA7F0',
  'app-green': {
    0: '#7FBA64',
    1: '#00850D',
    2: '#00A610',
  },
  'app-plum': '#6A2E47',
  'app-ice': '#C8D8E9',
  'app-poppy': '#EC5659',
  'app-blue': '#95B6F9',
  'app-success': '#00A610',
  'app-red': '#B30808',
  'app-description': '#9C9C9C',
}

export default {
  content: ['./src/**/*.{js,ts,jsx,tsx,mdx}'],
  theme: {
    extend: {
      fontFamily: {
        sans: ['TT Commons Classic', ...defaultTheme.fontFamily.sans],
        serif: ['Portonovo', ...defaultTheme.fontFamily.serif],
      },
      backgroundImage: {
        'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
        'gradient-conic': 'conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))',
      },
      colors: appColors,
      screens: {
        sm: '500px',
        md: '960px',
        lg: '1440px',
        xl: '1920px',
      },
    },
  },
  plugins: [],
} satisfies Config
