import { useSvgIcons } from './useSvgIcons'

export enum SvgIconType {
  HOME_ICON_OUTLINE = 'home_icon_outline',
  NEW_TALENT_ICON = 'new_talent_icon',
  NEW_PRO_ICON = 'new_pro_icon',
  RISING_PRO_ICON = 'rising_pro_icon',
  TOP_PRO_ICON = 'top_pro_icon',
  THATCH_ICON = 'thatch_icon',
  THATCH_CHAT_ICON = 'thatch_chat_icon',
  ARROW_UP_ICON = 'arrow_up_icon',
  CHECK_CIRCLE = 'check_circle',
  MENU_ICON = 'menu_icon',
  CLOSE_ICON = 'close_icon',
}

export interface SvgIconNativeProps {
  type: SvgIconType
  width?: number
  height?: number
  fill?: string
  viewBox?: string
  stroke?: string
  strokeWidth?: number
  onClick?: () => void
}

export const SvgIconNative: React.FC<SvgIconNativeProps> = ({
  type,
  width,
  height,
  viewBox,
  fill,
}) => {
  const icons = useSvgIcons({ width, height, viewBox, fill })

  return icons[type]
}
