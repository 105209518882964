import { Flex, Space } from '@mantine/core'
import { hasLength, isEmail, useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth'
import { useMemo, useState } from 'react'

import { AuthRedirectEventPayload } from '~/analytics/AuthEvent'
import { AppleSignInButton } from '~/components/auth/AppleSignInButton'
import { EmailButton } from '~/components/auth/EmailButton'
import { GoogleSignInButton } from '~/components/auth/GoogleSignInButton'
import { LoginForm, LoginFormValues } from '~/components/auth/LoginForm'
import { TermsAndServices } from '~/components/auth/Tos'
import { notify } from '~/components/shared/notify'
import { Typography } from '~/components/shared/text/Typography'
import { useAnalytics } from '~/context'

interface LoginHomeProps {
  isInlined?: boolean
  isInlinePurchase?: boolean
  onSwitchView?: (val: 'signup') => void
  onEmailAuthSuccess?: (userUid: string) => void
  authRedirectEventPayload: AuthRedirectEventPayload
}

export const LoginHome: React.FC<LoginHomeProps> = ({
  isInlined = false,
  onSwitchView,
  onEmailAuthSuccess,
  authRedirectEventPayload,
}) => {
  const [currentView, setCurrentView] = useState<'home' | 'login'>('home')
  const thatchSegment = useAnalytics()

  // extract destination url to be used for redirecting from register page
  const origDestination = useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    const destinationParamVal = params.get('destination')
      ? encodeURIComponent(params.get('destination') as string)
      : undefined ?? ''

    return destinationParamVal ? `?destination=${destinationParamVal}` : ''
  }, [])

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: isEmail('Enter a valid email'),
      password: hasLength({ min: 5 }, 'Password must be at least 6 characters'),
    },
  })

  const handleSubmit = async ({ email, password }: LoginFormValues) => {
    thatchSegment.trackMiscEvent('email_log_in_started', {})
    try {
      const { user } = await signInWithEmailAndPassword(getAuth(), email, password)
      if (user) {
        onEmailAuthSuccess?.(user.uid)
        thatchSegment.identitfyUser({ userId: user.uid, email: user.email })
        thatchSegment.trackMiscEvent('email_log_in_completed', {})
      }
    } catch (error) {
      console.error(error.code)
      notify(true, error.message)
    }
  }

  const handleForgotPassword = async () => {
    if (form.values.email) {
      await sendPasswordResetEmail(getAuth(), form.values.email)
      showNotification({
        autoClose: 3000,
        message: 'A password reset link has been sent to your email',
      })
    }
  }

  const handleSwitchViewClick = () => {
    onSwitchView?.('signup')
  }

  const homeView = (
    <>
      <Typography
        variant="h3Italic"
        mobileVariant="h2Italic"
        mb={{ base: 96, sm: 88 }}
      >
        Welcome{' '}
        <Typography
          span
          variant="h3"
          mobileVariant="h2"
        >
          back.
        </Typography>
      </Typography>

      <AppleSignInButton
        mb={16}
        label="Sign in with Apple"
        authRedirectEventPayload={authRedirectEventPayload}
      />
      <GoogleSignInButton
        mb={16}
        label="Sign in with Google"
        authRedirectEventPayload={authRedirectEventPayload}
      />
      <EmailButton
        label="Sign in with Email"
        onClick={() => setCurrentView('login')}
      />

      <Typography
        variant="body2"
        mobileVariant="body2"
        mobileVaraintSource="desktop"
        mb={{ base: 143, sm: 96 }}
        mt={{ base: 55, sm: 56 }}
      >
        No account?{' '}
        {isInlined ? (
          <Typography
            span
            color="appFuscia.0"
            variant="button_medium"
            mobileVariant="button_medium"
            mobileVaraintSource="desktop"
            onClick={handleSwitchViewClick}
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            Create One
          </Typography>
        ) : (
          <Typography
            span
            color="appFuscia.0"
            variant="button_medium"
            mobileVariant="button_medium"
            mobileVaraintSource="desktop"
            rawLink
            href={`/register${origDestination}`}
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            Create One
          </Typography>
        )}
      </Typography>
    </>
  )
  const loginView = (
    <>
      <Typography
        variant="h3"
        mobileVariant="h2"
        mb={72}
      >
        Log in{' '}
        <Typography
          span
          variant="h3Italic"
          mobileVariant="h2Italic"
        >
          with email.
        </Typography>
      </Typography>
      <LoginForm
        form={form}
        handleSubmit={handleSubmit}
      />
      <Space h={24} />
      <Typography
        variant="body2"
        mobileVariant="title2"
        align="center"
        color="appPlaceholder.0"
        sx={{
          cursor: form.values.email ? 'pointer' : 'not-allowed',
        }}
        onClick={handleForgotPassword}
      >
        Forgot Password
      </Typography>
      <Space h={40} />
      <Typography
        mb={{ base: 40, sm: 38 }}
        variant="button_medium"
        mobileVariant="button_medium"
        mobileVaraintSource="desktop"
        color="appFuscia.0"
        sx={{ cursor: 'pointer' }}
        onClick={() => setCurrentView('home')}
      >
        ← All log in options
      </Typography>
    </>
  )
  return (
    <>
      <Flex
        pt={isInlined ? { base: 74, sm: 96 } : 120}
        pb={isInlined ? { base: 15, sm: 40 } : 134}
        direction="column"
        align="center"
        justify="center"
        w="100%"
      >
        <Flex
          direction="column"
          align="center"
          justify="center"
          w="100%"
        >
          {currentView == 'home' ? homeView : loginView}
          <TermsAndServices source="Sign in" />
        </Flex>
      </Flex>
    </>
  )
}
